import React from 'react'
import './Parallax1.css'

const Parallax1 = () => {
  return (
    <div>
        <div className='parallax1'>
        <div className='right-p1'>
      </div>
      <div className='left-p1'>
        <div className='text-p1'>
          <hr/>
        <span className='text1'>Customized Fleet Solutions: </span> 
        <hr/>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Parallax1