import React from 'react'
import './Parallax4.css'


const Parallax4 = () => {
  return (
    <div>
        <div className='parallax4'>
        <div className='right-p4'>
      </div>
      <div className='left-p4'>
        <div className='text-p4'>
          <hr/>
        <span className='text1'>How It Works </span> 
        <hr/>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Parallax4