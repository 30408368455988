import React from 'react'
import './Hero.css'
import Logo from '../../assets/EXPLORE ECHOES_logo.png'
import Wheel from '../../assets/wheel.png'


const Hero = () => {
  return (
    <div className='hero'>
      <div className='right'>
        <div className='content'>
          <span className='text1-h'> Personalized Self-Guided Stories.</span>
        </div>
        
      </div>
      <div className='left'>
        <div>
          <img className='logo' src={Logo} alt="" />
        </div>
      </div>
    </div>
   
  );
};

export default Hero;
