import './App.css';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Hero from "./components/Hero/Hero";
import HowItWorks from './components/HowItWorks/HowItWorks';
import Join from './components/Join/Join';
import Parallax from './components/Parallax/Parallax';
import Parallax1 from './components/Parallax/Parallax1';
import Parallax2 from './components/Parallax/Parallax2';
import Parallax3 from './components/Parallax/Parallax3';
import Parallax4 from './components/Parallax/Parallax4';
import Services from "./components/Services/Services";
import Services1 from './components/Services/Services1';
import Services2 from './components/Services/Services2';
import Services3 from './components/Services/Services3';
import WhatIs from "./components/WhatIs/WhatIs";

function App() {
  return (
    <div className="App">
      <div>
        <Header/>
      </div>
      <div>
        <Hero />
      </div>
      <div>
        <WhatIs/>
      </div>
      <div id='Services'>
        <Parallax/>
      </div>
      <div>
        <Services/>
      </div>
      <div>
        <Parallax1/>
      </div>
      <div>
        <Services1/>
      </div>
      <div>
        <Parallax2/>
      </div>
     <div>
       <Services2/>
     </div>
     <div>
        <Parallax3/>
      </div>
     <div>
       <Services3/>
     </div>
     <div>
      <Join/>
     </div>
     <div id='About'>
      <Parallax4/>
     </div>
     <div >
      <HowItWorks/>
     </div>
     <div id='Contact'>
      <Contact/>
     </div>
     <div>
      <Footer/>
     </div>
    </div>
  );
}

export default App;
