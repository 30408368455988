import React from 'react'
import './Services3.css';
import img1 from '../../assets/undraw_chat-text.svg';
import img3 from '../../assets/undraw_Air_support_re_nybl.png';
import img4 from '../../assets/UBCO_Oregon_Coast-23_copy.jpg.webp';
import img5 from '../../assets/undraw_bike.svg';
import img6 from '../../assets/undraw_cloud-upload.svg';


const Services3 = () => {
  return (
    <div>
        <div>
      <div className='container-serv33'>
        <span className='text1'>
        Our commitment to customer satisfaction extends beyond the initial deployment.
        </span>
      </div>
        <div className='container-serv3'>
        <img className='img-serv3' src={img3} alt="" />
        <span className='text2'>
        With <span>dedicated support and maintenance services</span>, we ensure that your fleet remains in <span>peak condition, minimizing downtime and maximizing uptime</span>.
        </span>
        
        </div>
        <img className='img-serv33' src={img4} alt="" /> 
        <div className='container-serv3'>
        <span className='text2'>
        Whether you need <span> troubleshooting assistance, regular maintenance, or software updates</span>, our team is here to support you every step of the way.
        </span>
        </div>
        <div>
          <img className='icons' src={img1} alt="" />  
          <img className='icons' src={img5} alt="" />  
          <img className='icons' src={img6} alt="" />  
        </div>  
    </div>
    </div>
  )
}

export default Services3