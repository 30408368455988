import React, { useState } from 'react';
import './Services.css';

import img1 from '../../assets/IMG_0013.AVIF';
import img2 from '../../assets/IMG_0107.AVIF';
import img3 from '../../assets/IMG_0108.AVIF';
import img4 from '../../assets/IMG_0106.AVIF';
import img5 from '../../assets/IMG_0110.WEBP';
import img6 from '../../assets/IMG_0014 2.WEBP';
import img7 from '../../assets/IMG_0015 3.WEBP';
import img8 from '../../assets/IMG_0031.WEBP';
import img9 from '../../assets/IMG_0018 2.WEBP';
import battery from '../../assets/battery-full-svgrepo-com-2.svg';
import speed from '../../assets/speedometer-speed-fast-svgrepo-com.svg';
import distance from '../../assets/distance-svgrepo-com.svg';
import engine from '../../assets/engine-electricity-motor-svgrepo-com.svg';



const Services = () => {
  const [selectedImage1, setSelectedImage1] = useState(0);
  const [selectedImage2, setSelectedImage2] = useState(0);

  const handleImageClick1 = (index) => {
    setSelectedImage1(index);
  };

  const handleImageClick2 = (index) => {
    setSelectedImage2(index);
  };

  const images1 = [img1, img2, img3, img4];
  const images2 = [img5, img6, img7, img8, img9];

  return (
    <div className='bg'>
      <div className='container'>
        <span className='text2'>
          Our range of <span>ebikes & e-mopeds</span> combines sleek design
          with advanced technology, providing{' '}
          <span>low periods of maintenance services, eco-friendly options</span>{' '}
          for short-distance commuting & touring, and fleet monitoring
          management</span>
          <hr style={{ width: '70%'}} />
          <span className='text2'>
            Equipped with <span>high-performance batteries</span> and intuitive
            controls, our vehicles offer enhanced safety and convenience for both
            riders and operators.
          </span>   
      </div>
      <div className='container'>
      <span className='text1'>2X2 Adventure Bike</span>
        <div className='gallery-container'>
          {selectedImage1 !== null && (
            <img
              src={images1[selectedImage1]}
              alt={`Image ${selectedImage1 + 1}`}
              className='large-image'
            />
          )}
          <div className='thumbnail-container'>
            {images1.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className={`thumbnail ${
                  selectedImage1 === index ? 'selected' : ''
                }`}
                onClick={() => handleImageClick1(index)}
              />
            ))}
          </div>
        </div>
      </div>
      
      <div className='container'>
        <span className='text2'>
        Road registrable and ready for adventure, on- and off-road. Perfect for the urban commuter who dislikes traffic jams and the weekend adventurer who enjoys the sounds of nature. 
        </span>
        <div>
          <div className='specs'>
            <img className='specs-images' src={battery} alt="" /> <br/>
            <span className='text2'>Battery Capacity <br/></span> 
            <span className='text2'><span>1000Wh Dual Battery</span></span> 
          </div>
            
          <div className='specs'>
            <img className='specs-images' src={speed} alt="" /> <br/>
            <span className='text2'>Top Speed <br/></span>
            <span className='text2'><span>45 KM PER HOUR</span> </span> 
          </div>
          <div className='specs'>
            <img className='specs-images' src={distance} alt="" /> <br/>
            <span className='text2'>Battery Range <br/></span>
            <span className='text2'><span>120 KILOMETRES MAX RANGE</span></span>
          </div>
          <div className='specs'>
              <img className='specs-images' src={engine} alt="" /> <br/>
              <span className='text2'>Motor & Torque <br/></span>
              <span className='text2'><span>350W, 85Nm</span></span>
            </div> 
            <br/> 
          </div>
     </div>
      <div className='container'>
      <span className='text1'>Samurai Customize</span>
        <div className='gallery-container'>
          {selectedImage2 !== null && (
            <img
              src={images2[selectedImage2]}
              alt={`Image ${selectedImage2 + 1}`}
              className='large-image'
            />
          )}
          <div className='thumbnail-container'>
            {images2.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index + 1}`}
                className={`thumbnail ${
                  selectedImage2 === index ? 'selected' : ''
                }`}
                onClick={() => handleImageClick2(index)}
              />
            ))}
          </div>
        </div>
      </div>
      <div className='container'>
        <span className='text2'>
        The ZEN Samurai is a premium commuter, touring capable, <span>electric bike with Bosch Gen 4 Speed motor capable of 28mph</span>. Comes stock with a <span>Bosch PowerTube 500 battery pack and 4 amp fast charger</span>.
        </span>
        <div>
        <div className='specs'>
            <img className='specs-images' src={battery} alt="" /> <br/>
            <span className='text2'>Battery Capacity <br/></span> 
            <span className='text2'><span>1000Wh Dual Battery</span></span> 
          </div>
            
          <div className='specs'>
            <img className='specs-images' src={speed} alt="" /> <br/>
            <span className='text2'>Top Speed <br/></span>
            <span className='text2'><span>28 Mph</span> </span> 
          </div>
          <div className='specs'>
            <img className='specs-images' src={distance} alt="" /> <br/>
            <span className='text2'>Battery Range <br/></span>
            <span className='text2'><span>Upto 110 Miles</span></span>
          </div>
          <div className='specs'>
              <img className='specs-images' src={engine} alt="" /> <br/>
              <span className='text2'>Motor & Torque <br/></span>
              <span className='text2'><span>350W, 85Nm</span></span>
            </div> 
            <br/> 
          </div>
     </div>
    </div>
  );
};

export default Services;
