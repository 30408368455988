import React from 'react'
import './WhatIs.css'
import img1 from '../../assets/undraw_Ride_a_bicycle_re_6tjy.png';
import img2 from '../../assets/undraw_bike_ride_7xit.png';
import img3 from '../../assets/2x2_All_Wheel_Drive_01.jpg.webp';

const WhatIs = () => {
  return (
    <div className='whatis'>
        <span className='text1' >Explore Echoes </span>
        <hr style={{ width: '70%'}} />
        <div className='container-whatis'>
        <img className='img-whatis' src={img1} alt="" />
        <span className='text2'>
         Explore Echoe is a <span>leading provider of innovative personalized</span> (for the B2B end) self-guided local stories (through an app – voice map for the B2C end) <span>tailored for the modern travelers</span> who’s visiting a place.
        </span>
        </div>
        <img className='img-whatis2' src={img3} alt="" /> 
        <div className='container-whatis'>
        <span className='text2'>With a commitment to <span>sustainability, efficiency, and top-end user experience,</span> we offer a variety of <span>electric mobility products</span> and services designed to meet the evolving needs of businesses in accommodation establishments and more... </span>
        <img className='img-whatis' src={img2} alt="" /> 
        </div>
    </div>
  )
}

export default WhatIs