import React from 'react'
import './Services1.css';
import img1 from '../../assets/undraw_switches_1js3.png';
import img2 from '../../assets/undraw_Preferences_re_49in.png';
import img3 from '../../assets/undraw_person.svg';
import img4 from '../../assets/undraw_bike.svg';
import img5 from '../../assets/undraw_pointer.svg';

const Services1 = () => {
  return (
    <div>
      <div className='container-serv11'>
        <span className='text1'>
        At Explore Echoe, we understand that <span>every business has unique requirements</span>. 
        </span>
      </div>
      <br/>
        <div className='container-serv1'>
        <img className='img-serv1' src={img1} alt="" />
          <span className='text2'>That's why we offer <span>customized fleet & data</span> of a certain area solutions tailored to your specific needs. </span>
        <img className='img-serv1' src={img2} alt="" />
        </div> 
        <div className='container-serv1'>
        <span className='text2'>Whether you're a <span>hospitality provider, a residential provider, a rental vehicle company</span> or an individual businessman our team works closely with you to design and deploy a fleet that <span>optimizes efficiency, reduces costs, and enhances your brand image</span></span>
        </div>
        <div>
          <img className='icons' src={img3} alt="" />  
          <img className='icons' src={img4} alt="" />  
          <img className='icons' src={img5} alt="" />  
        </div>  
    </div>
  )
}

export default Services1