import React from 'react'
import './Parallax2.css'

const Parallax2 = () => {
  return (
    <div>
        <div className='parallax2'>
        <div className='right-p2'>
      </div>
      <div className='left-p2'>
        <div className='text-p2'>
          <hr/>
        <span className='text1'>Data-driven <br/> Analytics:  </span> 
        <hr/>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Parallax2