import React from 'react'
import './HowItWorks.css'
import img1 from '../../assets/fleet.png';
import img2 from '../../assets/IMG_0021.WEBP';
import img3 from '../../assets/IMG_0073.jpg';
import img4 from '../../assets/img4.png';
import img5 from '../../assets/IMG_0032.PNG';
import img6 from '../../assets/digital-marketing-ts-100598676-orig-4.jpg.webp';
import img7 from '../../assets/undraw_arrow-2.svg';


const HowItWorks = () => {
  return (
    <div className='bg-hiw'>
        <br/> <br/>
        <div className='swipe'>
            <span>SWIPE</span>
            <div>
                <img className='icons' src={img7} alt="" />  
            </div>
        </div>  
        <div className='items'>
            {/* item1 */}
            <div id='item1'  className='container-hiw'>
                <span className='text1'>1</span>
                <img className='img-hiw' src={img1} alt="" />
                <span className='text2'>We provide you with the vehicles (you can ither rent or buy). <br/><br/><br/><br/> </span>
            </div>
            {/* item2 */}
            <div id='item2'  className='container-hiw'>
                <span className='text1'>2</span>
                <img className='img-hiw' src={img2} alt="" />
                <span className='text2'>Vehicles are locked inside your dedicated geofence by using the B2B monitoring tool.<br/><br/><br/></span>
            </div>
            {/* item3 */}
            <div id='item3' className='container-hiw'>
                <span className='text1'>3</span>
                <img className='img-hiw' src={img3} alt="" />
                <span className='text2'>We set up the local routes (self-guided tours) in the B2C application (these routes could be walking routes as well).</span>
            </div>
            {/* item4 */}
            <div id='item4' className='container-hiw'>
                <span className='text1'>4</span>
                <img className='img-hiw' src={img4} alt="" />
                <span className='text2'>Routes are enriched with local providers (restaurants, places of attraction etc.).<br/><br/><br/></span>
            </div>
            {/* item5 */}
            <div id='item5' className='container-hiw'>
                <span className='text1'>5</span>
                <img className='img-hiw' src={img5} alt="" />
                <span className='text2'>We upload your tours in Explore Echoe website.<br/><br/><br/><br/></span>
            </div>
            {/* item6 */}
            <div id='item6' className='container-hiw'>
                <span className='text1'>6</span>
                <img className='img-hiw' src={img6} alt="" />
                <span className='text2'>Your tours are promoted through various channels (the basis of those tours is your business).<br/></span>
            </div>
        </div>
        

    </div>
  )
}

export default HowItWorks