import React, { useState } from 'react';
import './Header.css'

const Header = () => {

    const [menuOpen, setMenuOpen] = useState(false);
      
    const handleMenuToggle = () => {
      setMenuOpen(!menuOpen);
    };
    return (
        <div className='header'>
          <div className={`nav-menu ${menuOpen ? 'open' : ''}`}>
            <a className="nav-btn" href="/">Home</a>
            <a className="nav-btn" href="#Services">Services</a>
            <a className="nav-btn" href="#About">About</a>
            <a className="nav-btn" href="#Contact">Contact</a>
          </div>
          <button className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={handleMenuToggle}>
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
        
  )
}

export default Header