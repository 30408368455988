import React from 'react'
import './Parallax.css'

const Parallax = () => {
  return (
    <div className='parallax'>
        <div className='right-p'>
      </div>
      <div className='left-p'>
        <div className='text-p'>
          <hr/>
        <span className='text1'>Cutting-edge <br/> e-bikes and <br/>  e-mopeds: </span> 
        <hr/>
        </div>
      </div>
    </div>
  )
}

export default Parallax