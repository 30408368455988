import React from 'react'
import './Join.css'
import img1 from '../../assets/undraw_different_love_a3rg.png';
import img3 from '../../assets/undraw_heart.svg';
import img4 from '../../assets/undraw_bike.svg';
import img5 from '../../assets/undraw_tree.svg';


const Join = () => {
  return (
    <div>
        <br/><br/><br/>
     <div className='join'>
        <div className='container-join'>
        <span className='text1' >Join the Electric Mobility Revolution:  </span> 
        <span className='text2'>Embrace the <span>future of electric mobility</span> with Explore Echoe.</span>
        </div>
        <hr style={{ width: '70%'}} />
        <br/><br/><br/>
        <div className='container-join'>
        <span className='text2'>
        Partner with us to unlock the <span>full potential of your business</span> through electric mobility, <span>reduce your environmental footprint, and drive sustainable growth</span> for your business.
        </span>
        </div>
        <img className='img-join' src={img1} alt="" /> 
        <div className='container-join'>
        <span className='text2'>
            Together, we can create <span>smarter, greener solutions</span> for generations to come.    
        </span>
        </div>
        <div>
          <img className='icons' src={img3} alt="" />  
          <img className='icons' src={img4} alt="" />  
          <img className='icons' src={img5} alt="" />  
        </div> 
     </div>
    </div>
  )
}

export default Join