import React from 'react'
import './Parallax3.css'

const Parallax3 = () => {
  return (
        <div>
        <div className='parallax3'>
        <div className='right-p3'>
    </div>
    <div className='left-p3'>
        <div className='text-p3'>
        <hr/>
        <span className='text1'>Dedicated Support and Maintenance:  </span> 
        <hr/>
        </div>
    </div>
    </div>
    </div>
  )
}

export default Parallax3