import React from 'react'
import './Footer.css'
import Instagram from "../../assets/instagram.png";
import Linkedin from "../../assets/linkedin.png";
import Facebook from "../../assets/facebook-svgrepo-com.png";
import Logo from "../../assets/logo.png";


const Footer = () => {
  return (
    <div className='Footer-container'>
        <div className='footer'>
          <br/><br/>
            <div className='menu'>
            <a className="nav-btn" href="./">Home</a>
            <a className="nav-btn" href="#Services">Services</a>
            <a className="nav-btn" href="#About">About</a>
            <a className="nav-btn" href="#Contact">Contact</a>
            </div>
            <br/><br/>
            <div className='social-links'>
                <img src={Instagram} alt="" />
                <img src={Facebook} alt="" />
                <img src={Linkedin} alt="" />
            </div>
            <br/><br/>
            <div className='logo-f'>
                <img src={Logo} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Footer