import React from 'react'
import './Services2.css';
import img1 from '../../assets/undraw_All_the_data_re_hh4w.png';
import img2 from '../../assets/unnamed_52aab659-6742-4223-ae35-d8abd71f34f9_1200x.jpg.webp';
import img3 from '../../assets/undraw_Customer_survey_re_v9cj.png';
import img4 from '../../assets/UBCO_Oregon_Coast-23_copy.jpg.webp';

const Services2 = () => {
  return (
    <div>
      <div className='container-serv22'>
        <span className='text1'>
        In today's competitive landscape, <span>data is key to making informed business decisions</span>.
        </span>
      </div>
        <div className='container-serv2'>
        <img className='img-serv2' src={img1} alt="" />
        <span className='text2'>
         Explore Echoe provides <span>powerful analytics tools</span> that give you valuable insights into your <span>fleet's performance, usage patterns, and operational efficiency</span>.
        </span>
        </div>
        <img className='img-serv22' src={img2} alt="" />
        <div className='container-serv2'>
        <span className='text2'>
        By harnessing this data, you can <span>identify opportunities for improvement, maximize ROI, and stay ahead of the curve</span> in the rapidly evolving electric mobility market.    
        </span>
        <img className='img-serv2' src={img3} alt="" /> 
        </div>
    </div>
  )
}

export default Services2